import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-6h', to: 'now', display: 'Last 6 hours' },
  { from: 'now-24h', to: 'now', display: 'Last 24 hours' },
  { from: 'now-2d', to: 'now', display: 'Last 2 days' },
  { from: 'now-3d', to: 'now', display: 'Last 3 days' },
  { from: 'now-7d', to: 'now', display: 'Last 7 days' },
  { from: 'now-14d', to: 'now', display: 'Last 14 days' },
  { from: 'now-30d', to: 'now', display: 'Last 30 days' },
  { from: 'now-2h', to: 'now+6h', display: 'now-2h to now+6h' },
  { from: 'now-12h', to: 'now+6h', display: 'now-12h to now+6h' },
  { from: 'now-12h', to: 'now+48h', display: 'now-12h to now+48h' },
  { from: 'now-12h', to: 'now+60h', display: 'now-12h to now+60h' },
  { from: 'now-24h', to: 'now+12h', display: 'now-24h to now+12h' },
  { from: 'now-36h', to: 'now+12h', display: 'now-36h to now+12h' },
  { from: 'now-1d', to: 'now+2d', display: 'now-1d to now+2d' },
  { from: 'now-1d', to: 'now+4d', display: 'now-1d to now+4d' },
  { from: 'now-2d', to: 'now+7d', display: 'now-2d to now+7d' },
  { from: 'now-7d', to: 'now+7d', display: 'now-7d to now+7d' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];
